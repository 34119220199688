<template>
  <div class="assessment">
    <navbar title="房产评估" path="/my/index" />
    <div class="head">
      <img class="img" src="../../assets/images/banklogo.png" />
    </div>
    <div class="middle">
      <div class="middle-input">
        <van-field
          v-model="address"
          label="房屋住址："
          placeholder="请输入小区名或地址"
          :rules="[{ required: true, message: '请输入小区名或地址' }]"
        />
      </div>
      <div class="middle-btn">
        <van-button type="info" class="inquiry" name="button" @click="inquiry"
          >立即询价</van-button
        >
      </div>
    </div>
    <div class="bottom">
      <p class="bottom-item">声明</p>
      <p class="bottom-item1">
        1.本房产评估所有产品服务及相关责任由厦门中利资产评估土地房地产估价有限公司承担。客服电话：0592-2299528
      </p>
      <p class="bottom-item1">
        2.本次询价基于询价人输入的房屋信息进行评估，估价师保留对询价对象进行现场查勘后价格调整的权利。
      </p>
      <p class="bottom-item1">
        3.本次询价为房产抵押价值认定的评估，询价结果仅作参考，如有特定用途请委托专业估价机构出具相应房地产估价报告。
      </p>
    </div>
    <div class="bottom-img">
      <img src="../../assets/images/homepagebg.png" />
    </div>
  </div>
</template>

<script>
import navbar from "../../components/NavBar.vue";

export default {
  name: "houseAssessment",
  data() {
    return {
      address: "",
    };
  },
  methods: {
    inquiry() {
      if ((this.address == "")) {
          this.$toast('请输入小区名称或地址！');
      } else {
        this.$router.push({
          path: "/assessment",
          query: {
            name: this.address,
          },
        });
      }
    },
  },

  components: {
    navbar,
  },
};
</script>

<style lang="less" scoped>
.assessment {
  background-color: rgb(216, 250, 244);
  min-height: 100vh;

  .head {
    height: 230px;
    img {
      width: 210px;
      height: 60px;
      position: relative;
      top: 95px;
      left: 90px;
    }
  }
  .middle {
    height: 230px;
  }
  /deep/.van-cell {
    height: 49px;
    width: 80%;
    position: relative;
    left:40px;
  }
  /deep/.van-field__label {
    color: black;
    font-size: 16px;
    margin-right: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.van-field__control {
    font-size: 16px;
    padding-top: 2px;
  
  }
  .inquiry {
    background: #00d3c4;
    height: 49px;
    width: 80%;
    border: none;
    position: relative;
    top: 40px;
    left: 40px;
  }
  /deep/.van-button__text {
    font-size: 16px;
  }
  .bottom-item {
    font-size: 12px;
    color: #999999;
    display: flex;
    justify-content: center;
  }
  .bottom-item1 {
    font-size: 12px;
    color: #999999;
    margin-left: 10px;
    margin-right: 8px;
  }
  .bottom-img {
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
    /deep/.van-toast--text{
    position: absolute;
    top: 600px;
  }
  }
}
</style>